var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [
      _c("app-modal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isModalVisible,
            expression: "isModalVisible"
          }
        ],
        attrs: { modal: _vm.modal },
        on: { "submit-modal": _vm.handleSubmit }
      }),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        _vm._l(_vm.userLeaves.items, function(userLeave, index) {
          return _c(
            "div",
            {
              key: userLeave.id,
              staticClass: "col-lg-2 col-md-3 col-sm-6 col-12",
              attrs: { height: "100%" }
            },
            [
              _c("leave-card", {
                attrs: { userLeave: userLeave, index: index },
                on: {
                  "open-edit-modal": function($event) {
                    return _vm.editLeave(userLeave, index)
                  }
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _c("v-row", { attrs: { align: "center", justify: "center" } }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("leaves-table", {
              attrs: {
                tableName: _vm.leaveTables.USER_HISTORY_REQUESTS,
                leaves: _vm.userLeavesRequests,
                loading: _vm.loading
              },
              on: {
                updateOptions: _vm.updateOptions,
                deleteLeaveRequest: _vm.deleteLeaveRequest
              }
            })
          ],
          1
        )
      ]),
      _c(
        "v-fab-transition",
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-5",
                              attrs: {
                                color: "primary",
                                fab: "",
                                bottom: "",
                                right: "",
                                fixed: ""
                              },
                              on: { click: _vm.newLeaveRequest }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("layout.accountNavMenu.requestNewLeave")))
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }