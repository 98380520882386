<template>
  <v-container fluid class="px-lg-5">
    <app-modal v-show="isModalVisible" :modal="modal" @submit-modal="handleSubmit"/>
    <v-row align="center" justify="center">
      <div class="col-lg-2 col-md-3 col-sm-6 col-12" height="100%"
        v-for="(userLeave, index) in userLeaves.items" :key="userLeave.id">
        <leave-card :userLeave="userLeave" :index="index" @open-edit-modal="editLeave(userLeave, index)"/>
      </div>
    </v-row>
    <v-row align="center" justify="center">
      <div class="col-12">
        <leaves-table :tableName="leaveTables.USER_HISTORY_REQUESTS"
                      :leaves="userLeavesRequests"
                      :loading="loading"
                      @updateOptions="updateOptions"
                      @deleteLeaveRequest="deleteLeaveRequest">
        </leaves-table>
      </div>
    </v-row>
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab bottom right fixed class="mr-5" v-on="on"
            v-bind="attrs" @click="newLeaveRequest">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('layout.accountNavMenu.requestNewLeave') }}</span>
      </v-tooltip>
    </v-fab-transition>
  </v-container>
</template>

<script>
import moment from 'moment';
import LeaveCard from 'components/leaves/Card.vue';
import leavesTable from 'components/leaves/Table.vue';
import {mapActions, mapGetters} from 'vuex';
import {
  leaveStatus,
  leaveUnits,
  leaveTables
} from 'constants';

export default {
  name: 'UserLeaves',
  components: {LeaveCard, leavesTable},
  data() {
    return {
      moment,
      leaveTables,
      leaveStatus,
      leaveUnits,
      isModalVisible: false,
      selectedLeaveIndex: 0,
      jiraTask: '',
      loading: true,
      title: {
        text: this.$tc('models.userLeave.entity', 2),
        icon: 'mdi-account-group'
      },
      modal: {
        show: false,
        id: '',
        behaviour: '',
        resetForm: false,
        title: '',
        submitButton: {
          label: this.$t('actions.save')
        },
        fields: {
          totalBalance: {
            type: 'text',
            key: 'totalBalance',
            value: '',
            label: this.$t('models.userLeave.attributes.totalBalance'),
            hint: this.$t('messages.hints.jiraConventions'),
            classes: 'col-12 py-0',
            rules: [{name: 'required'}],
            serverErrors: []
          },
          jiraTask: {
            type: 'autocomplete',
            key: 'jiraTask',
            value: '',
            autocompleteAction: this.searchJira,
            options: [],
            itemText: 'desc',
            itemValue: 'id',
            isLoading: false,
            clearable: true,
            label: this.$t('models.userLeave.attributes.jiraTask'),
            classes: 'col-12 py-0',
            serverErrors: []
          }
        }
      }
    };
  },
  computed: mapGetters(['userLeaves', 'userLeavesRequests', 'jiraTasks']),
  methods: {
    ...mapActions([
      'getUserLeaves',
      'updateUserLeave',
      'getLeaveRequests',
      'getUserLeavesRequests',
      'destroyLeaveRequest',
      'searchJiraTasks',
      'checkJiraTask'
    ]),
    handleSubmit(data) {
      if (!data.jira_task) {
        return this.save(data);
      }
      this.checkJiraTask({
        task: data.jira_task, uuid: this.$route.params.uuid, id: this.modal.id
      }).then((taken) => {
        if (taken) {
          this.$confirm(this.$t('messages.confirmation.duplicateJiraTasks'), {
            buttonTrueText: this.$t('actions.confirm'),
            buttonFalseText: this.$t('actions.cancel')
          }).then((confirmed) => {
            if (confirmed) {
              this.save(data);
            } else {
              this.modal.fields.jiraTask.value = this.jiraTask;
            }
          });
        } else {
          this.save(data);
        }
      });
    },
    save(data) {
      this.updateUserLeave({
        uuid: this.$route.params.uuid,
        id: this.modal.id,
        leave: data,
        index: this.selectedLeaveIndex
      }).then((response) => {
        if (this.successCode(response.status)) {
          this.modal.show = false;
        } else {
          this.displayInlineServerErrors(this.modal.fields, response.errors);
        }
      });
    },
    editLeave(data, index) {
      this.clearInlineServerErrors(this.modal.fields);
      this.modal.resetForm = false;
      this.modal.title = data.name;
      this.modal.fields.totalBalance.value = data.totalBalance.convention;
      this.modal.fields.jiraTask.value = data.jiraTask;
      this.jiraTask = data.jiraTask;
      this.searchJira(data.jiraTask);
      this.modal.id = data.id;
      this.selectedLeaveIndex = index;
      this.modal.show = true;
      this.$nextTick(() => {
        this.modal.resetForm = false;
      });
    },
    searchJira(val) {
      if (this.modal.fields.jiraTask.isLoading) return;

      if (!this._.isNil(val) && val.length >= 3) {
        this.modal.fields.jiraTask.isLoading = true;
        this.searchJiraTasks({searchKey: val, uuid: this.$route.params.uuid}).then(() => {
          this.modal.fields.jiraTask.options = this.jiraTasks.items
            .map((task) => ({id: task.key, desc: `${task.key} ${task.summary}`}));
          this.modal.fields.jiraTask.isLoading = false;
        });
      }
    },
    updateOptions(options) {
      options = {
        ...options,
        filterBy: {
          ...options.filterBy,
          userId: this.$route.params.uuid
        }
      };
      this.loading = true;
      this.getLeaveRequests(options).then(() => {
        this.loading = false;
      });
    },
    newLeaveRequest() {
      this.$router.push({name: 'NewLeaveRequest', params: {uuid: this.$route.params.uuid}});
    },
    deleteLeaveRequest(data) {
      this.destroyLeaveRequest({
        id: data.id, index: data.index, table: 'user'
      });
    }
  },
  created() {
    this.getUserLeaves({uuid: this.$route.params.uuid});
  }
};
</script>
